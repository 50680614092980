<template>
    <div class="site-wrapper site-page--login">
        <div class="site-content__wrapper">
            <div class="site-content">
                <div class="brand-info" :style="{'width':leftWidth+'px'}">
                    <h2 class="brand-info__text">简阳养老服务信息平台</h2>
                    <p class="brand-info__intro"></p>
                </div>
                <div class="login-main">
                    <div class="login-inner">
                        <h3 class="login-title">管理员登录</h3>
                        <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" status-icon>
                            <el-form-item prop="userName">
                                <el-input v-model="dataForm.userName" placeholder="帐号"></el-input>
                            </el-form-item>
                            <el-form-item prop="password">
                                <el-input v-model="dataForm.password" type="password" placeholder="密码"></el-input>
                            </el-form-item>
                            <el-form-item prop="captcha">
                                <el-row>
                                    <el-col :span="14">
                                        <el-input v-model="dataForm.captcha" placeholder="验证码">
                                        </el-input>
                                    </el-col>
                                    <el-col :span="10">
                                        <div class="login-captcha">
                                            <img :src="captchaPath" @click="getCaptcha()" alt="">
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                            <el-form-item>
                                <el-button class="login-btn-submit" type="primary" @click="dataFormSubmit()">登录</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getUUID} from '@/utils'
import {login} from '../api/indexApi'
export default {
    data() {
        return {
            dataForm: {
                userName: '',
                password: '',
                uuid: '',
                captcha: ''
            },
            dataRule: {
                userName: [
                    {required: true, message: '帐号不能为空', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: '密码不能为空', trigger: 'blur'}
                ],
                captcha: [
                    {required: true, message: '验证码不能为空', trigger: 'blur'}
                ]
            },
            captchaPath: ''
        }
    },
    computed:{
        leftWidth(){
            let clientWidth = document.documentElement['clientWidth']
            let loginWidth = 430
            var w = clientWidth * 0.6 - (clientWidth * 0.4 % -loginWidth);
            return w;
        }
    },
    created() {
        this.getCaptcha()
    },
    methods: {
        // 提交表单
       dataFormSubmit() {
            // this.$router.replace({name: 'home'})
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    this.login()
                    // this.$http({
                    //     url: this.$http.adornUrl('/sys/login'),
                    //     method: 'post',
                    //     data: this.$http.adornData({
                            // 'username': this.dataForm.userName,
                            // 'password': this.dataForm.password,
                            // 'uuid': this.dataForm.uuid,
                            // 'captcha': this.dataForm.captcha
                    //     })
                    // })
                    // .then(({data}) => {
                    //     if (data && data.code === 0) {
                    //         this.$cookie.set('token', data.token)
                    //         console.log('token', data.token);
                    //         this.$router.replace({name: 'index'})
                    //     } else {
                    //         this.getCaptcha()
                    //         this.$message.error(data.msg)
                    //     }
                    // })
                }
            })
        },
        // 登录 
        async login() {
            let result = await login({
                'username': this.dataForm.userName,
                'password': this.dataForm.password,
                'uuid': this.dataForm.uuid,
                'captcha': this.dataForm.captcha
            })
            if(result.code == 0) {
                window.localStorage.setItem('token',result.token)
                this.$message({
                    message: '登录成功',
                    type: 'success',
                    duration: 2500
                })
                this.$router.push('/index')
            }else {
                this.$message({
                    message: result.msg,
                    type: 'error',
                    duration: 2500
                })
            }
        },
        // 获取验证码
        async getCaptcha() {
            this.dataForm.uuid = getUUID()
            // let result = await getCode({
            //     uuid: this.dataForm.uuid
            // })
            this.captchaPath = `${window.BASE_URL}/captcha.png?uuid=${this.dataForm.uuid}`;
        }
    }
}
</script>

<style lang="scss">
    .site-wrapper.site-page--login {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(38, 50, 56, .6);
        overflow: hidden;

        &:before {
            position: fixed;
            top: 0;
            left: 0;
            right:0;
            bottom:0;
            height:100%;
            min-width:800px;
            z-index: -1;
            content: "";
            background-image: url(~@/image/login_bg2.jpg);
            background-size: cover;
            background-position:center center;
        }

        .site-content__wrapper,.site-content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 0;
            margin: 0;
            overflow: hidden;
            background-color:transparent;
        }

        .brand-info {
            width:645px;
            padding-right:50px;
            padding-left:10px;
            position:absolute;
            right:40%;
            top:50%;
            margin-top:-150px;

            color: #fff;
            .brand-info__text {
                margin: 0 0 22px 0;
                font-size: 40px;
                font-weight: 400;
                text-transform: uppercase;
            }
            .brand-info__intro {
                margin: 10px 0;
                font-size: 16px;
                line-height: 1.58;
                opacity: .75;
            }
        }

        .login-main {
            width: 430px;
            height:480px;
            position: absolute;
            left: 60%;
            top:50%;
            margin-top:-230px;
            .login-inner{
                width: 100%;
                background-color: #fff;
                border-radius: 12px;
                padding: 40px 60px 36px 60px;
            }
            .login-title {
                text-align: left;
                font-size: 24px;
                font-weight: 600;
                color: #263142;
            }
            .login-captcha {
                overflow: hidden;
                display:flex;
                align-items:center;
                height:45px;
                > img {
                    width: 100%;
                    cursor: pointer;
                    display:block;
                }
            }
            .login-btn-submit {
                width: 100%;
                margin-top: 38px;
                font-size: 16px;
                height: 40px;
                text-align: center;
                border-radius: 25px;
                outline: none;
                box-shadow: rgba(88, 243, 176, 0.2) 0px 4px 10px 0px;
            }
            .el-form{
                .el-form-item{
                    margin-bottom:22px;
                    .el-input{
                        .el-input__inner{
                            border-width: 0 0 1px 0;
                            border-radius: 0;
                            padding:0 26px 0 0;
                            height:46px;
                            line-height:46px;
                        }
                        .el-input__suffix{
                            right:0;
                        }
                    }
                }
            }
        }
    }
</style>
